import { CoreMenu } from '@core/types';
import moment from 'moment';

export const menu: CoreMenu[] = [
  {
    id: 'dashboard',
    title: 'Irányítópult',
    type: 'item',
    icon: 'home',
    url: 'kezdolap',
  },

  // Értékesítés
  {
    id: 'ertekesites-section',
    type: 'section',
    title: 'Értékesítés',
  },

  {
    id: 'ertekesites',
    title: 'Értékesítés',
    type: 'item',
    icon: 'shopping-cart',
    url: 'torzsek/ertekesites',
  },

  // Bizonylatok
  {
    id: 'bizonylatok',
    type: 'section',
    title: 'Bizonylatok',
  },
  {
    id: 'keszletkezeles',
    title: 'Készletkezelés',
    type: 'collapsible',
    icon: 'list',
    children: [
      {
        id: 'egyeb-raktari-bevet',
        title: 'Egyéb raktári bevét',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/egyeb-raktari-bevet',
      },
      {
        id: 'egyeb-raktari-kivet',
        title: 'Egyéb raktári kivét',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/egyeb-raktari-kivet',
      },
      {
        id: 'raklapkezeles',
        title: 'Raklapkezelés',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/raklapkezeles',
      },
      {
        id: 'leltar',
        title: 'Leltár',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/leltar',
      },
      {
        id: 'cikkarazas',
        title: 'Cikk árazás',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/cikkarazas',
      },
      {
        id: 'raktarkeszlet',
        title: 'Raktárkészlet',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/raktarkeszlet',
      },
      {
        id: 'beszerzes-visszaigazolas',
        title: 'Beszerzés visszaigazolás',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/beszerzes-visszaigazolas',
      },
    ],
  },
  {
    id: 'penzugyi-bizonylatok',
    title: 'Bizonylatok',
    type: 'collapsible',
    icon: 'credit-card',
    children: [
      {
        id: 'ajanlat',
        title: 'Árajánlat',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/ajanlat',
      },
      {
        id: 'bevetelezes',
        title: 'Bejövő szállítólevél',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/bevetelezes',
      },
      {
        id: 'bejovo-szamla',
        title: 'Bejövő számla',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/bejovo-szamla',
      },
      {
        id: 'bejovo-koltseg',
        title: 'Bejövő költség',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/bejovo-koltseg',
      },
      {
        id: 'dijbekero',
        title: 'Díjbekérő',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/dijbekero',
      },
      {
        id: 'elolegszamla',
        title: 'Előlegszámla',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/elolegszamla',
      },
      {
        id: 'foglalas',
        title: 'Foglalás',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/foglalas',
      },
      {
        id: 'kimenoszamla',
        title: 'Kimenő számla',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/kimeno-szamla',
      },
      {
        id: 'kulsoraktarieladas',
        title: 'Külső raktári eladás',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/kulso-raktari-eladas',
      },
      {
        id: 'nyugta',
        title: 'Nyugta',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/nyugta',
      },
      {
        id: 'penztar',
        title: 'Pénztár',
        type: 'item',
        icon: 'codesandbox',
        url: `torzsek/penztar/${moment().format('YYYY-MM-DD')}`,
      },
      {
        id: 'szallitolevel',
        title: 'Szállítólevél',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/szallitolevel',
      },
      {
        id: 'utalasi-jegyzek',
        title: 'Utalási jegyzék',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/utalasi-jegyzek',
      },
    ],
  },

  // Koordinátor
  {
    id: 'koordinator',
    type: 'section',
    title: 'Koordinátor',
    icon: 'box',
  },

  {
    id: 'cegadatok-collapsible',
    title: 'Cégadatok',
    type: 'collapsible',
    icon: 'book',
    children: [
      {
        id: 'bankszamlaszamok',
        title: 'Bankszámlaszámok',
        type: 'item',
        icon: 'dollar-sign',
        url: 'torzsek/bankszamlaszamok',
      },
      {
        id: 'bizonylattipusok',
        title: 'Bizonylattípusok',
        type: 'item',
        icon: 'edit-3',
        url: 'torzsek/bizonylattipusok',
      },
      {
        id: 'cegadatok',
        title: 'Cégadatok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/cegadatok',
      },
      {
        id: 'feladat-kategoriak',
        title: 'Feladat kategóriák',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/feladat-kategoriak',
      },
      {
        id: 'naptar-kategoriak',
        title: 'Naptár kategóriák',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/naptar-kategoriak',
      },
      {
        id: 'mozgaskodok',
        title: 'Mozgáskódok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/mozgaskodok',
      },
      // {
      //   id: 'szervezetiegysegek',
      //   title: 'Szervezeti egységek',
      //   type: 'item',
      //   icon: 'codesandbox',
      //   url: 'torzsek/szervezetiegysegek',
      // },
    ],
  },
  {
    id: 'cikktorzs',
    title: 'Cikktörzs',
    type: 'item',
    icon: 'file-text',
    url: 'torzsek/cikktorzs',
  },
  {
    id: 'cikk-csoportositas',
    title: 'Cikk csoportosítás',
    type: 'item',
    icon: 'file-text',
    url: 'torzsek/cikk-csoportositas',
  },
  {
    id: 'cikkcsoportok',
    title: 'Cikkcsoportok',
    type: 'item',
    icon: 'file-text',
    url: 'torzsek/cikkcsoportok',
  },
  {
    id: 'cim-adatok',
    title: 'Cím adatok',
    type: 'collapsible',
    icon: 'book-open',
    children: [
      {
        id: 'cimtipusok',
        title: 'Címtípusok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/cimtipusok',
      },
      {
        id: 'megyek',
        title: 'Megyék',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/megyek',
      },
      {
        id: 'nyelvek',
        title: 'Nyelvek',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/nyelvek',
      },
      {
        id: 'orszagok',
        title: 'Országok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/orszagok',
      },
      {
        id: 'telepulesek',
        title: 'Települések',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/telepulesek',
      },
    ],
  },

  {
    id: 'dolgozoi-adatok',
    title: 'Dolgozói adatok',
    type: 'collapsible',
    icon: 'briefcase',
    children: [
      {
        id: 'dolgozok',
        title: 'Dolgozók',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/dolgozok',
      },
      {
        id: 'felhasznaloicsoportok',
        title: 'Felhasználói csoportok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/felhasznaloicsoportok',
      },
      {
        id: 'feor',
        title: 'FEOR',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/feor',
      },
      {
        id: 'munkanapok',
        title: 'Munkanapok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/munkanapok',
      },
      {
        id: 'onelszamoloegysegek',
        title: 'Önelszámoló egységek',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/onelszamoloegysegek',
      },
    ],
  },
  // {
  //   id: 'ertekesites',
  //   title: 'Értékesítés',
  //   type: 'item',
  //   icon: 'file-text',
  //   url: 'torzsek/ertekesites',
  // },
  {
    id: 'keszlet',
    title: 'Készlet adatok',
    type: 'collapsible',
    icon: 'list',
    children: [
      {
        id: 'mennyisegiegysegek',
        title: 'Mennyiségi egységek',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/mennyisegiegysegek',
      },
      {
        id: 'raktarak',
        title: 'Raktárak',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/raktarak',
      },
    ],
  },

  {
    id: 'partnerek',
    title: 'Partnerek',
    type: 'item',
    icon: 'users',
    url: 'torzsek/partnerek',
  },

  {
    id: 'penzugyi-adatok',
    title: 'Pénzügyi adatok',
    type: 'collapsible',
    icon: 'credit-card',
    children: [
      {
        id: 'afakulcsok',
        title: 'Áfakulcsok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/afakulcsok',
      },
      {
        id: 'arkategoriak',
        title: 'Árkategóriák',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/arkategoriak',
      },
      {
        id: 'arfolyamok',
        title: 'Árfolyamok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/arfolyamok',
      },
      {
        id: 'fizetesimodok',
        title: 'Fizetési módok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/fizetesimodok',
      },
      {
        id: 'konyveloi',
        title: 'Könyvelői export',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/konyveloi',
      },
      {
        id: 'fokonyviszamok',
        title: 'Főkönyvi számok',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/fokonyviszamok',
      },
      // {
      //   id: 'kintlevosegek',
      //   title: 'Kintlévőségek',
      //   type: 'item',
      //   icon: 'codesandbox',
      //   url: 'torzsek/kintlevosegek',
      // },
      {
        id: 'ki-be-fizetesi-jogcimek',
        title: 'Ki-be fizetési jogcímek',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/ki-be-fizetesi-jogcimek',
      },
      {
        id: 'koltseg-kategoriak',
        title: 'Költség kategóriák',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/koltseg-kategoriak',
      },
      {
        id: 'penzintezetek',
        title: 'Pénzintézetek',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/penzintezetek',
      },
      {
        id: 'penznemek',
        title: 'Pénznemek',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/penznemek',
      },
      {
        id: 'penztarak',
        title: 'Pénztárak',
        type: 'item',
        icon: 'codesandbox',
        url: 'torzsek/penztarak',
      },
    ],
  },

  {
    id: 'analitika',
    title: 'Analitika',
    type: 'item',
    icon: 'bar-chart',
    //url: 'torzsek/analitika',
  },
];
